<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1000px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    v-model="editedItem.code"
                    :label="'Ref'"
                    dense
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-text-field
                    v-model="editedItem.label"
                    label="Designation"
                    :rules="[(v) => !!v || 'Designation obligatoire']"
                    dense
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.mont_ttc"
                    :label="'Montant'"
                    :rules="[
                      (v) => !!v || 'Montant obligatoire',
                      (v) => v > 0 || 'Valeur incorrecte ',
                      (v) =>
                        !v ||
                        qte_max == 0 ||
                        parseFloat(v) <= parseFloat(qte_max) ||
                        'Quantité Max ' + qte_max,
                    ]"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="12">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_DP_DETAIL from "../graphql/DP/CREATE_DP_DETAIL.gql";
import UPDATE_DP_DETAIL from "../graphql/DP/UPDATE_DP_DETAIL.gql";

export default {
  components: {},
  name: "dpdetailform",
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    qte_max: { type: Number, default: 0 },
  },
  data: () => ({
    datepicker1: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {},
    editedItem: {},
  }),

  computed: {
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
    }
  },

  methods: {
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    close() {
      this.$emit("close", this.items);
    },

    async save() {
      if (this.$refs.form.validate()) {
        let v;

        if (this.editedItem.id > 0) {
          v = {
            dpdetail: {
              id: this.editedItem.id,
              mont_ttc: this.editedItem.mont_ttc,
              label: this.editedItem.label,
              code: this.editedItem.code,
              comment: this.editedItem.comment,

              write_uid: this.$store.state.me.id,
            },
          };
          this.maj(UPDATE_DP_DETAIL, v, true);

          //this.items.splice(this.item.index, 1, this.editedItem);
          this.close();
        } else {
          v = {
            dpdetail: {
              dp_id: this.item.dp_id,
              mont_ttc: this.editedItem.mont_ttc,
              label: this.editedItem.label,
              code: this.editedItem.code,
              comment: this.editedItem.comment,

              write_uid: this.$store.state.me.id,
            },
          };
          v = await this.maj(CREATE_DP_DETAIL, v, true);
          this.editedItem.id = v.createDPDetail.id;

          // this.items.push(this.editedItem);
          this.close();
        }
      }
    },
  },
};
</script>
